import { OrganizationData } from '@typings/api/storefronts';
import { atom, createStore } from 'jotai/vanilla';
import { Bio } from '../../../types/vendor/properties';

export interface OrganizationState {
	serviceAreas: string | null;
	travelAvailability: number | null;
	startYear: number | null;
	totalMembers: number | null;
	videographyServices: boolean;
	photographyServices: boolean;
	languages: string[] | null;
	teamMembers: Bio[];
}

const initialState: OrganizationState = {
	serviceAreas: null,
	travelAvailability: null,
	startYear: null,
	totalMembers: null,
	videographyServices: false,
	photographyServices: false,
	languages: null,
	teamMembers: [],
};

export const serviceAreasAtom = atom(initialState.serviceAreas);
export const travelAvailabilityAtom = atom(initialState.travelAvailability);
export const startYearAtom = atom(initialState.startYear);
export const totalMembersAtom = atom(initialState.totalMembers);
export const videographyServicesAtom = atom(initialState.videographyServices);
export const photographyServicesAtom = atom(initialState.photographyServices);
export const languagesAtom = atom(initialState.languages);
export const teamMembersAtom = atom(initialState.teamMembers);

export const setOrganizationData = (
	store: ReturnType<typeof createStore>,
	organizationData: OrganizationData['storefront'],
) => {
	if (organizationData.orgDetails) {
		store.set(serviceAreasAtom, organizationData.orgDetails.serviceAreas);
		store.set(
			travelAvailabilityAtom,
			organizationData.orgDetails.travelAvailability,
		);
		store.set(startYearAtom, organizationData.orgDetails.startYear);
		store.set(totalMembersAtom, organizationData.orgDetails.totalMembers);
		store.set(
			videographyServicesAtom,
			organizationData.orgDetails.videographyServices,
		);
		store.set(
			photographyServicesAtom,
			organizationData.orgDetails.photographyServices,
		);
		store.set(languagesAtom, organizationData.orgDetails.languages);
	}

	store.set(teamMembersAtom, organizationData.teamMembers);
};

export const getOrganizationDataToHydrate = (
	store: ReturnType<typeof createStore>,
) => {
	return {
		serviceAreas: store.get(serviceAreasAtom),
		travelAvailability: store.get(travelAvailabilityAtom),
		startYear: store.get(startYearAtom),
		totalMembers: store.get(totalMembersAtom),
		videographyServices: store.get(videographyServicesAtom),
		photographyServices: store.get(photographyServicesAtom),
		languages: store.get(languagesAtom),
		teamMembers: store.get(teamMembersAtom),
	};
};

export const getOrganizationInitialValues = (
	values: ReturnType<typeof getOrganizationDataToHydrate>,
) => {
	const {
		serviceAreas,
		travelAvailability,
		startYear,
		totalMembers,
		videographyServices,
		photographyServices,
		languages,
		teamMembers,
	} = values || {};

	return [
		[serviceAreasAtom, serviceAreas || initialState.serviceAreas],
		[
			travelAvailabilityAtom,
			travelAvailability || initialState.travelAvailability,
		],
		[startYearAtom, startYear || initialState.startYear],
		[totalMembersAtom, totalMembers || initialState.totalMembers],
		[
			videographyServicesAtom,
			videographyServices || initialState.videographyServices,
		],
		[
			photographyServicesAtom,
			photographyServices || initialState.photographyServices,
		],
		[languagesAtom, languages || initialState.languages],
		[teamMembersAtom, teamMembers || initialState.teamMembers],
	];
};
